<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {required, requiredIf} from "vuelidate/lib/validators";
import {toastHelper} from "../../../helpers/toast-helper";

/**
 * Instructions Category Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,
      languageId: "",

      table: {
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,

        elements: []
      },

      category: {
        defaultImage: "",
        title: ""
      },

      imageFile: null,
      imageExtension: "",

      visibleModal: false,
    };
  },

  validations: {
    imageFile: {
      required: requiredIf(function() {
        return !this.imageFile && !this.category.defaultImage;
      })
    },

    category: {
      orderValue: { required },
      title: { required }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('instruction-categories.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "defaultImage", slot: true, label: this.$t('instruction-categories.table.image') },
        { key: "title", label: this.$t('instruction-categories.table.title') },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    async loadCategories() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/instruction-category/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            languageId: this.languageId,
          }
        });

        this.table.items = data.instructionCategories
        this.table.totalRows = data.count
        this.table.rows = data.count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    deleteCategory(category) {
      this.submitted = true;
      swalHelper.yesOrNo(() => {
        const json = {
          "id": category.id
        }

        axios.delete(`/instruction-category`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: json
        }).then(() => {
          toastHelper.success(this.$t('instruction-categories.delete.success'))
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        });
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.visibleModal = false;
      this.category =  {
        defaultImage: "",
        title: ""
      }
    },

    editCategory(category) {
      this.category = Object.assign({}, category);
      this.visibleModal = true
    },

    async createOrEditCategory() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.imageFile) {
        try {
          await this.upload()
        } catch (error) {
          return
        }
      }

      const json = {
        ...this.category,
        languageId: this.languageId
      };

      axios.put(`/instruction-category`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        toastHelper.success(this.$t(this.category.id ? "instruction-categories.edit.success" : "instruction-categories.create.success"))
        this.$refs.table.refresh();
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    uploadImage(input) {
      const image = input.target.files[0];
      this.imageFile = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        const dataUrl = event.target.result;
        this.imageExtension = dataUrl.split(';')[0].split('/')[1];
      };
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.imageFile);
      formData.append('extension', this.imageExtension)

      try {
        const { data } = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.category.defaultImage = data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },


  },

  beforeMount() {
    this.languageId = this.$route.params.languageId;
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('instruction-categories.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a class="clickable-element btn btn-success mb-2 mx-2" @click="visibleModal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('instruction-categories.create.button') }}
              </a>
            </div>

            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadCategories"
                :pagination-top="true"
                :table="table">
              <template v-slot:defaultImage="{ item }">
                <img :src="item.defaultImage" alt="default image" class="rounded avatar-lg">
              </template>

              <template v-slot:action="{ item }">
                <div style="width: 120px;">
                  <a @click="editCategory(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                  <a @click="deleteCategory(item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                </div>
              </template>
            </ecat-table>
        </div>
      </div>
    </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        size="xl"
        :scrollable="true"
        v-model="visibleModal"
        :title="$t(category.id ? 'instruction-categories.edit.title' : 'instruction-categories.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label>Kolejność wyświetlania</label>
          <input v-model.number="category.orderValue" class="form-control"
                 :class="{ 'is-invalid': submitted && $v.category.orderValue.$error }"/>
          <div v-if="submitted && !$v.category.orderValue.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label for="image">{{ $t('instruction-categories.table.image') }}</label>
          <br>

          <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="image" @change="uploadImage" :class="{ 'is-invalid': $v.imageFile.$error }">
          <div v-if="!$v.imageFile.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="title">{{ $t('instruction-categories.table.title') }}</label>
          <input id="title" v-model="category.title" type="text" name="title" class="form-control" :class="{ 'is-invalid': submitted && $v.category.title.$error }" />
          <div v-if="submitted && !$v.category.title.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditCategory" variant="success">{{ $t(category.id ? 'instruction-categories.edit.button' : 'instruction-categories.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>